import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import conf from '@/assets/js/conf'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    areaTree:[]
  },
  getters: {
  },
  mutations: {

    setAreaTree(state,payload){
      state.areaTree = payload
    }
  },
  actions: {
    setAreaTree(context){
      
      axios.get(conf.api.areaTree).then(res=>{
        console.log(res)
        context.commit("setAreaTree",res.data.data)
      }).catch(err=>{
        console.log(err)
      })
    }

  },
  modules: {
  }
})
