import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'

import Meta from 'vue-meta'

import ElementUI from 'element-ui'
import './styles.scss'

import './assets/css/base.css'

Vue.use(ElementUI)

Vue.use(Meta)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
