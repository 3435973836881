<template>
  <div id="app">
    <div class="left-nav-box">

    </div>
    <div class="view-box">
      <div>
        <router-view/>
      </div>
      
    </div>
    
  </div>
</template>

<script>

export default{

  metaInfo: {
    title: "你的故事给我鼓励",
    meta: [
      { name: "keywords", content: "燕选农人" },
      { name: "description", content: "燕选农人" },
      {name:"viewport", content:"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"}
    ],
  },

  mounted(){
    
    this.$store.dispatch("setAreaTree")
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  background-color: var(--main-color)
}

.left-nav-box{
  width: 13%;
  height: 100vh;
  background: url(./assets/image/left.png) 0 0 no-repeat;
  background-size: 100% 100%;
}
.view-box{
  width: 87%;
  height: 100vh;
}
.view-box>div{
  margin:20px
}
</style>
