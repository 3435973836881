export default{
    api:{

      getQiniuToken:"/api/files/gettoken",

      areaTree:"/api/area/index",
      
      upStation:"/api/station/save",
      readStation:"/api/station/read"
    
    },
    qiniuPath:"nongren/0/",
    async getQiniuToken(axios,ext,table_word){
     
    
        const res = await axios.get(this.api.getQiniuToken+"?ext="+ext+"&table_word="+table_word);
 
        return res;
       
    },

    upFile(file,qiniu,data,fun){
  
        const observable = qiniu.upload(file, this.qiniuPath+data.cover, data.token)

            const observer = {
                next(res){
                  // ...
                  console.log("next res",res)
                },
                error(err){
                  // ...
                  console.log("error:err",err)
                },
                complete(res){
                  // ...
                  console.log("complete res:",res)
                  fun(res)
                }
              }
           
            observable.subscribe(observer) // 上传开始
    },
    getFormData(time){
      time = time || new Date()
    
      var str=""

      str+= time.getFullYear()+"年"
      str+= time.getMonth()+"月"
      str+= time.getDate()+"日"
      return str;
    }
}