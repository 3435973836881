<template>
  <div class="view-page">

    <div class="carousel-parent">
      <!-- 走马灯 -->
      <el-carousel height="70vh" indicator-position="none">
        <!-- <el-carousel-item v-for="item in 4" :key="item" :class="'carousel-'+item">
          <h3 class="small">{{ item }}</h3>
        </el-carousel-item> -->

        <el-carousel-item class="carousel-item">
          <img class="share-image" :src="qiniuHost+qiniuPath+info.cover"/>
        </el-carousel-item>
      </el-carousel>


      <!-- 上部logo -->
      <div class="info-logo">
        <img src="../assets/image/logo.png"/>
      </div>

      <!-- 下部内容 -->
      <div class="info-content-box">

        <div v-if="info.addr" class="info-content-box-position">
          <div class="pos-box">
            <img src="../assets/image/pos.png"/> 
            {{ info.addr }}
            <!-- {{$store.state.areaTree[info.province].name}} -->

            <!-- <span v-if="info.city>-1 && $store.state.areaTree[info.province].list.length>0"> 
              &nbsp;-&nbsp;
              {{ $store.state.areaTree[info.province].list[info.city].name }}
            </span> -->
          </div>
        </div>

        <div class="info-content-box-darkbg">
          <div class="info-content">
            {{info.content}}
        
          </div>
          <div class="ercode-box">
              <!-- <img v-if="info.cover>0" :src="qiniuHost+qiniuPath+info.cover"/> -->
              <!-- :logoSrc="lgoImg"
                :logoScale="0.21"
                :logoMargin="25" -->
              <vue-qr
                :text="host+'?id='+info.id"   
                :size="200"
                :margin="10"
                colorDark="#333"
                backgroundColor="#eee"
              
                
              ></vue-qr>
          </div>
        </div>
        
      </div>


      <!-- 第n封心情 -->
      <!-- <div class="share-num-box"> 
        <div>
          <div>
            第63518封
          </div>
        </div>
      </div> -->


    </div>
    
    
    <!--  -->

    <div class="share-info">
      “ {{today}}<br/>
你阅读了来自网友的第{{total}}封心情分享 ”
    </div>

  

    <!-- <div class="toshare-box"> -->
     
      <el-button type="primary" class="common-btn" @click="toUpstation">我也要分享此刻心情   →</el-button>
    <!-- </div> -->

  </div>
</template>

<script>
// @ is an alias to /src
import conf from '@/assets/js/conf'
import VueQr from 'vue-qr'
export default {
  name: 'HomeView',
  components:{
    VueQr
  },
  data(){
    return {
      info:{
        id:0,
        uid:0,
        cover:19,
        content:"万事有不平，尔何空自空。长将一寸身，衔木到终古。我愿平东海，身沉志不改。大海无平期，我心无绝时。呜呼!君不见西山衔木众鸟多，鹊来燕去自成窠。 顾炎武 "
      },
      total:100,
      qiniuHost:"",
      qiniuPath:"",
      ercode:"",
      host:"",
      today:""
    }
  },

  mounted(){
    this.qiniuHost = process.env.VUE_APP_QINIUHOST
    this.qiniuPath = conf.qiniuPath
  
    this.getInfo(this.$route.query.id)

    this.host = "https://"+window.location.host
   
    this.today=conf.getFormData()
  },
  methods:{
    toUpstation(){
      this.$router.push({path:"/upstation"})
    },
    getInfo(id){
      console.log(id)
      var _this = this
      this.$axios.get(conf.api.readStation+"?id="+id).then(res=>{
        console.log(res)
        if(res.status==200 && res.data.code==1){
          _this.info = res.data.data.info
          _this.total = res.data.data.total
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

.carousel-parent{
  position: relative;
  background-color: var(--weight-color)
}

.share-info{
  margin-top: 25px;
  text-align: left;
  padding:0 20px;
  line-height: 30px;
}

.info-content-box{
  position: absolute;
  /* bottom: 32px; */
  bottom: 0;
  width: 100%;
  
  z-index: 9;
}
.info-content-box-position{
  text-align: left;
}
.pos-box{
  padding:20px;
  display: flex;
  align-items: center;
  background: url(../assets/image/posbg.png) 0 0 no-repeat;
  background-size: 100% 100%;
  width: fit-content;
}
.pos-box img{
  height: 25px;
  margin-right: 10px;
}
.info-content-box-darkbg{
  display: grid;
  grid-template-columns: 3fr 1fr;
  background: rgba(0,0,0,0.4);
  padding:30px;
  box-sizing: border-box;
  grid-gap: 20px;
}

.info-logo{
  position: absolute;
  top: 0;
  left: 0;
  text-align: left 
}
.info-logo img{
  width: 50%;
}

.share-image{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.info-content{
  text-align: left;
  color: #fff;
}

.share-num-box{
  position: absolute;
  right: 0;
  color: #FF5541FF;
  font-weight: 800;
  font-size: 45px;
 
}

.ercode-box img{
  width: 100%;
}

.default-img{
  filter:blur(5px)
}
  
</style>
